import { HttpClient } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';

import { Auth, GoogleAuthProvider, signInWithPopup } from '@angular/fire/auth';
import { Observable, from } from 'rxjs';
import { environment } from '../../environments/environment';
import { EncryptService } from '../shared/encrypt.service';
import { ServiceBase } from '../shared/service-base';
import { SessionService } from '../shared/session.service';
import { AlterarSenhaMultiplasContasRequestEntity } from './entities/alterar-senha-multiplas-contas-request.entity';
import { UsuarioEntity } from './entities/usuario.entity';
import { ConsultarUsuariosCadastroSenhaResponseEntity } from './entities/responses/consultar-usuarios-cadastro-senha-response.entity';

@Injectable({
  providedIn: 'root'
})
export class LoginService extends ServiceBase {
  constructor(
    @Optional() private auth: Auth,
    private http: HttpClient,
    private sessionService: SessionService,
    private encriptyService: EncryptService
  ) { super(); }

  consultarCodigoUsuarioEmailCpfCelular(identificacao: string): Observable<number> {
    return this.http.get<number>(`${environment.apiSeguranca}v2/autenticacao/${identificacao}`);
  }

  consultarCodigoUsuarioSemSenha(usuario: string): Observable<number> {
    return this.http.get<number>(`${environment.apiSeguranca}autenticacao/UsuarioPossuiSenhaNula?usuario=${usuario}`);
  }

  consultarGrupoCredorUsuario(hash: string): Observable<number> {
    return this.http.get<number>(`${environment.apiSeguranca}v2/autenticacao/grupocredor/${hash}`);
  }

  autenticarIdentificacaoSenha(usuario: UsuarioEntity): Observable<any> {
    const securityEntry = this.encriptyService.encryptData(JSON.stringify(usuario));
    return this.http.post<any>(`${environment.apiSeguranca}v2/autenticacao`, { hash: securityEntry });
  }

  autenticarGoogle(): Observable<any> {
    const provider = new GoogleAuthProvider();

    provider.addScope('profile');
    provider.addScope('email');

    return from(signInWithPopup(this.auth, provider));
  }

  autenticarUsuarioFirebase(googleUid: string, email: string, codigoSistema: number): Observable<string> {
    return this.http.get<string>(`${environment.apiSeguranca}v2/autenticacao/AutenticarUsuarioFirebase?hash=${googleUid}&email=${email}&codigoSistema=${codigoSistema}`);
  }

  autoSignInApp(chaveSeguranca: string, hash: string, codigoApp: number, usuario: string) {
    return this.http.get<number>(`${environment.apiSeguranca}autenticacao/AutoSignInApp/${chaveSeguranca}/${hash}/${codigoApp}/${usuario}`);
  }

  enviarTokensValidacao(usuario: UsuarioEntity, tipo: string): Observable<any> {
    return this.http.post<any>(`${environment.apiSeguranca}autenticacao/EnviarTokensValidacao/${tipo}`, usuario);
  }

  validarEmailCelularUsuario(codigoUsuario: number): Observable<void> {
    return this.http.get<void>(`${environment.apiSeguranca}autenticacao/ValidarEmailCelularUsuario/${codigoUsuario}`);
  }

  recuperarSenha(usuario: string, urlCallBack: string | null = null): Observable<object> {
    return this.http.get<object>(`${environment.apiSeguranca}v2/autenticacao/recuperarSenha/${usuario}/${environment.codigoSistemaFeev}/${urlCallBack}`);
  }

  alterarSenhaMultiplasContas(entity: AlterarSenhaMultiplasContasRequestEntity): Observable<void> {
    return this.http.put<void>(`${environment.apiSeguranca}Autenticacao/alterarSenhaMultiplasContas/`, entity);
  }

  consultarUsuariosCadastroSenha(usuario: string): Observable<ConsultarUsuariosCadastroSenhaResponseEntity[]> {
    return this.http.get<ConsultarUsuariosCadastroSenhaResponseEntity[]>(`${environment.apiSeguranca}Autenticacao/ConsultarUsuariosCadastroSenha?usuario=${usuario}`);
  }
}
