<ng-template #modalAlterarSenha let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-ficha">Alterar senha</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="d()">
    </button>
  </div>
  <div class="modal-body">
    <form #frmAlterarSenha="ngForm">
      <app-input type="password" inputGroupClass="input-group" placeholder="Nova senha" name="isenha"
                 title="Nova senha" maxlength="32" [(ngModel)]="alterarSenhaRequest.senha" required></app-input>
      <app-input type="password" placeholder="Repetir a nova senha" name="confirmacaoSenha" title="Repetir nova senha"
                 maxlength="32" [(ngModel)]="alterarSenhaRequest.confirmacaoSenha" (keyup)="onkeyUp($event)" required></app-input>
      <div class="my-3 text-center">
        <button class="btn btn-primary btn-sm btn-block text-uppercase" type="button" (click)="alterarSenha(frmAlterarSenha);">
          SALVAR NOVA SENHA
        </button>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-sm btn-secondary loat-right right" (click)="c()" ngbAutofocus>Fechar</button>
  </div>
</ng-template>
